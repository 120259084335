<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="8"
        lg="6"
      )
        h1.text-h4.mb-4 {{ verbage }} Tech
        v-card(
          outlined
        )
          v-card-text
            FeathersVuexFormWrapper(
              :item="item"
            )
              template(v-slot="{ clone, save, reset, isDirty }")
                v-form(
                  @submit.prevent="save().then(handleSaveReponse)"
                  ref="form"
                  v-model="valid"
                )
                  v-row
                    v-col
                      v-text-field(
                        label="Name"
                        filled
                        v-model.trim="clone.name"
                        :rules="[rules.required]"
                      )
                  v-row(
                    v-if="user.isAdmin || user.isRegionAdmin"
                  )
                    v-col
                      FeathersVuexFind(
                        service="shops"
                        :query="{}"
                        v-slot="{ items: shops, isFindPending }"
                        watch="params"
                      )
                        v-autocomplete(
                          prepend-inner-icon="mdi-magnify"
                          label="Shop"
                          :loading="isFindPending"
                          :items="shops"
                          item-text="name"
                          item-value="id"
                          v-model="clone.shop_id"
                          :rules="[rules.required]"
                          filled
                        )

                  v-row
                    v-col
                      v-btn.mb-5(
                        color="secondary"
                        type="submit"
                        block
                        height="50px"
                        :disabled="!valid || !isDirty"
                      ) {{ verbage }} Tech
                      v-btn(
                        block
                        @click="cancel"
                      ) Cancel
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'

export default {
  name: 'EditTech',
  components: {
    FeathersVuexFormWrapper
  },
  mixins: [
    validations
  ],
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    id () {
      return this.$route.params.techId
    },
    shopId () {
      return this.$route.query.shopId
    },
    verbage () {
      return this.id === 'new' ? 'Add' : 'Edit'
    },
    // Returns a new Tech if the route `id` is 'new', or returns an existing Tech.
    item () {
      const { Tech } = this.$FeathersVuex.api

      return this.id === 'new' ? new Tech({ shop_id: this.shopId }) : Tech.getFromStore(this.id)
    }
  },
  watch: {
    id: {
      handler (val) {
        // Early return if the route `:id` is 'new'
        if (val === 'new') {
          return
        }
        const { Tech } = this.$FeathersVuex.api
        const existingRecord = Tech.getFromStore(val)

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          Tech.get(val)
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true
    }
  },
  methods: {
    handleSaveReponse (savedTech) {
      this.$router.push({ name: 'shop-techs', params: { shopId: savedTech.shop_id } })
    },
    cancel () {
      if (this.id === 'new') {
        if (this.shopId) {
          this.$router.push({ name: 'shop-techs', params: { shopId: this.shopId } })
        } else {
          this.$router.push({ name: 'techs' })
        }
      } else {
        this.$router.push({ name: 'shop-techs', params: { shopId: this.item.shop_id } })
      }
    }
  }
}
</script>
