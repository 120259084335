var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_c('h1',{staticClass:"text-h4 mb-4"},[_vm._v(_vm._s(_vm.verbage)+" Tech")]),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var clone = ref.clone;
var save = ref.save;
var reset = ref.reset;
var isDirty = ref.isDirty;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();save().then(_vm.handleSaveReponse)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Name","filled":"","rules":[_vm.rules.required]},model:{value:(clone.name),callback:function ($$v) {_vm.$set(clone, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"clone.name"}})],1)],1),(_vm.user.isAdmin || _vm.user.isRegionAdmin)?_c('v-row',[_c('v-col',[_c('FeathersVuexFind',{attrs:{"service":"shops","query":{},"watch":"params"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var shops = ref.items;
var isFindPending = ref.isFindPending;
return [_c('v-autocomplete',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Shop","loading":isFindPending,"items":shops,"item-text":"name","item-value":"id","rules":[_vm.rules.required],"filled":""},model:{value:(clone.shop_id),callback:function ($$v) {_vm.$set(clone, "shop_id", $$v)},expression:"clone.shop_id"}})]}}],null,true)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mb-5",attrs:{"color":"secondary","type":"submit","block":"","height":"50px","disabled":!_vm.valid || !isDirty}},[_vm._v(_vm._s(_vm.verbage)+" Tech")]),_c('v-btn',{attrs:{"block":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }